const MainRoutes = {
  path: '/',
  meta: {
    requiresAuth: true,
  },
  redirect: '/',
  component: () => import('@/layouts/main/MainLayout.vue'),
  children: [
    {
      name: 'Start',
      path: '/',
      component: () => import('@/views/main/start.vue'),
    },
    {
      name: 'Main',
      path: '/main',
      component: () => import('@/views/main/index.vue'),
    },
  ],
};

export default MainRoutes;
